import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/reset.css' // 引入 Ant Design 样式
import router from './router' // 引入 Vue 路由
import '@fortawesome/fontawesome-free/css/all.css' // 引入 FontAwesome 样式

const app = createApp(App)

// 使用 Ant Design 组件库
app.use(Antd)

// 使用路由
app.use(router)

// 挂载 Vue 实例
app.mount('#app')
