<template>
  <div class="home">
    <video class="home-video" autoplay muted loop src="@/assets/home-video.mp4"></video>
    <div class="home-content">
      <div>
        <div v-if="!isMobile" class="top-title"
          style="position:absolute;top: 0;left: 0;width: 100%;background-color: rgba(0,0,0,0.3);display: flex;align-items: center;justify-content: center;font-size: 12px;padding: 10px 0px;font-weight: 500;">

          <span style="margin-right: 60px;">自研协议：国内外都能用，敏感期不断网🎉</span>
          <span style="margin-right: 60px;">国内优化：本地网有多快，翻墙猫就有多快🎉</span>
          <span style="margin-right: 60px;">按量付费：不限时间，不限设备，月底不重置流量🎉
          </span>
          <!-- <span>开业大酬宾：</span> -->
          <!-- <span>免费下载并注册试用</span> -->
          <!-- <span style="color: #28a745; font-weight: bold;">年付仅需 29.9¥&nbsp;</span> -->
          <span>低至 <span style="color: #28a745; font-weight: bold;">9.9¥不限时流量</span>&nbsp;立即体验，畅享高速网络！</span>
          <span>🎉</span>
        </div>

        <div v-if="isMobile"
          style="position:absolute;top: 0;left: 0;width: 100%;background-color: #0a162b;display: flex;align-items: center;justify-content: space-between;padding: 2% 5%;font-weight: 600;">
          <dvi style="font-size: 18px;font-weight: 600;font-size: .53rem;">
            <!-- <img src="@/assets/ios_logo.jpg" style="width: 10%; height: auto;border-radius: 50%;"> -->
            CatsVPN
          </dvi>


          <a v-if="isAndroid" :href="androidURL" download style="display: contents;">
            <dvi @click="isAndroid?downloadAndroid():showModalTf()"
              style="background: linear-gradient(180deg,#6ba6e8,#0030ff);text-align: center;border-radius: 3rem;padding: 2.5% 4.3%;font-size: .48309rem;font-weight: 600;">
              下载APP
            </dvi>
          </a>


          <dvi v-if="!isAndroid" @click="showModalTf()"
            style="background: linear-gradient(180deg,#6ba6e8,#0030ff);text-align: center;border-radius: 3rem;padding: 2.5% 4.3%;font-size: .48309rem;font-weight: 600;">
            下载APP
          </dvi>


        </div>

        <h1 class="content-title">{{isMobile?"永远能连上的VPN":翻墙猫VPN}}</h1>
        <div class="content-introduce-text">
          <h1 v-if="!isMobile" class="content-introduce-1" ref="typewriter"></h1>

          <div v-if="isMobile" style="font-size: .43478rem !important;font-weight: 500;">
            <div>
              <ul style="display: flex;align-items: center;list-style:none">
                <li><img style="width: .96618rem;height: .96618rem;" src="@/assets/desc_aTel_gnew.5e61092e.png" alt="">
                </li>
                <li style="margin-left: 0.35rem;">自主研发协议， 断网期也能用</li>
              </ul>
            </div>

            <div>
              <ul style="display: flex;align-items: center;list-style:none">
                <li><img style="width: .96618rem;height: .96618rem;" src="@/assets/desc_bTel_gnew.e980e78f.png" alt="">
                </li>
                <li style="margin-left: 0.35rem;">按量付费， 低至9.9¥不限时流量</li>
              </ul>
            </div>

            <div>
              <ul style="display: flex;align-items: center;list-style:none">
                <li><img style="width: .96618rem;height: .96618rem;" src="@/assets/desc_cTel_gnew.d5082260.png" alt="">
                </li>
                <li style="margin-left: 0.35rem;">不限速，网有多快，翻墙猫就多快</li>
              </ul>
            </div>

            <div>
              <ul style="display: flex;align-items: center;list-style:none">
                <li><img style="width: .96618rem;height: .96618rem;" src="@/assets/desc_dTel_gnew.99c321e7.png" alt="">
                </li>
                <li style="margin-left: 0.35rem;">不限时间，不限设备，不重置流量</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="content-download" v-if="!isMobile">

          <a @click="showModalTf()">
            <img class="img-bg img-bg-appstore" src="@/assets/appstore-9ab4ce29.svg" />
            <img class="img-bg img-bg-appstore-mobile" src="@/assets/appstore-efeaa3ac.svg" />
          </a>
          <a :href="androidURL" download>

            <img class="img-bg img-bg-android" src="@/assets/android-342c5f80.svg" />
            <img class="img-bg img-bg-android-mobile" src="@/assets/android-7facdfae.svg" />
          </a>

          <a @click="isModalWin()">
            <img class="img-bg img-bg-windows" src="@/assets/windows-8cc347d3.svg" />
            <img class="img-bg img-bg-windows-mobile" src="@/assets/windows-bc1852bb.svg" />
          </a>
          <a @click="isModalMac">
            <img class="img-bg img-bg-mac" src="@/assets/mac-f79dac12.svg" />
            <img class="img-bg img-bg-mac-mobile" src="@/assets/mac-39a7e9c1.svg" />
          </a>
          <a @click="showModalTf()">
            <img class="img-bg img-bg-testflight" src="@/assets/testflight-0b34cd32.svg" />
            <img class="img-bg img-bg-testflight-mobile" src="@/assets/testflight-257ad22f.svg" />
          </a>
        </div>
      </div>

      <div v-if="isMobile">
        <div style="text-align: center;display: flex;justify-content: center;">
          <a v-if="isAndroid" :href="androidURL" download
            style="background: #0a162b;width: 80%;box-shadow: 0 .0161rem .02415rem .0161rem rgba(0,0,0,.4);border-radius: 8px;display: inline-block;margin-bottom: 15%;font-size: .64rem;padding: .2rem;font-weight: 600;align-items: center;justify-content: center; text-decoration: none;color: #fff;">
            <img style="margin-bottom: 15px;" :style="{ width: isAndroid ? '.80515rem' : '.69243rem' }"
              :src="require('@/assets/mobile_android.svg')" alt />
            <span>&nbsp;{{isAndroid?"Android":"iOS"}}&nbsp;版下载</span>
          </a>

          <a v-if="!isAndroid" @click="showModalTf()"
            style="background: #0a162b;width: 80%;box-shadow: 0 .0161rem .02415rem .0161rem rgba(0,0,0,.4);border-radius: 8px;display: inline-block;margin-bottom: 15%;font-size: .64rem;padding: .2rem;font-weight: 600;align-items: center;justify-content: center;">
            <img style="margin-bottom: 15px;" :style="{ width: isAndroid ? '.80515rem' : '.69243rem' }"
              :src="isAndroid ? require('@/assets/mobile_android.svg') : require('@/assets/mobile_ios.svg')" alt />
            <span>&nbsp;{{isAndroid?"Android":"iOS"}}&nbsp;版下载</span>
          </a>
        </div>
        <ul style="text-align: center;margin-bottom:2% !important">
          <li style="display: inline-block;width: 24%;vertical-align:middle">
            <hr style="background: #000;height: .016rem;border: none;">
          </li>
          <li style="font-size: .64412rem;display: inline-block;font-weight: 500;margin: auto .64412rem;">适用于</li>
          <li style="display: inline-block;width: 24%;vertical-align:middle">
            <hr style="background: #000;height: .016rem;border: none;">
          </li>
        </ul>
        <ul style="text-align: center;font-weight: 500;margin-bottom:0px !important;line-height: 18px;">
          <li style="width: 1.61031rem;
         
          display: inline-block;
          padding: .20129rem;
          margin: auto .24155rem;
          color: #fff;
          font-size: .28986rem;">
            <a :href="windowURL" download>
              <img style="width: .64412rem;" src="@/assets/mobile_win.svg">
              <p style="margin-bottom: 0px !important;">Windows</p>
            </a>
          </li>
          <li style="width: 1.61031rem;
         
          display: inline-block;
          padding: .20129rem;
          margin: auto .24155rem;
          color: #fff;
          font-size: .28986rem;">
            <a @click="isModalMac">
              <img style="width: .64412rem;" src="@/assets/mobile_mac.svg">
              <p style="margin-bottom: 0px !important;">macOs</p>
            </a>
          </li>
          <li style="width: 1.61031rem;
         
          display: inline-block;
          padding: .20129rem;
          margin: auto .24155rem;
          color: #fff;
          font-size: .28986rem;">
            <a :href="androidURL" download>
              <img style="width: .64412rem;" src="@/assets/mobile_android.svg">
              <p style="margin-bottom: 0px !important;">Android</p>
            </a>
          </li>
          <li style="width: 1.61031rem;
         
          display: inline-block;
          padding: .20129rem;
          margin: auto .24155rem;
          color: #fff;
          font-size: .28986rem;">
            <a @click="showModalTf()">
              <img style="width: .64412rem;" src="@/assets/mobile_ios.svg">
              <p style="margin-bottom: 0px !important;">iOS</p>
            </a>
          </li>
        </ul>
      </div>

      <div class="content-bottom" v-if="!isMobile">
        <div class="concat-wrap">
          <div class="item">
            <img class="icon" src="@/assets/mail-9f5862d7.svg" style="width: 2vh; height: 2vh;">
            <span class="icon icon-label">support@catsvpn.com</span>
          </div>
          <a class="item">
            <img class="icon" src="@/assets/help-a134d98d.svg" style="width: 2vh; height: 2vh;">
            <span class="icon icon-label">点击右下角图标联系客服</span>
          </a>
          <!-- <a class="item" href="https://t.me/palantirgroup">
            <img class="icon" src="@/assets/telegram-3a20213d.svg" style="width: 2vh; height: 2vh;">
            <span class="icon icon-label">Telegram</span>
          </a>
          <a class="item" href="https://twitter.com/xiaodiqiuyi">
            <img class="icon" src="@/assets/twitter-8cc35512.svg" style="width: 2vh; height: 2vh;">
            <span class="icon icon-label">Twitter</span>
          </a> -->
        </div>
        <span class="content-copyright">Copyright © CatsVPN Inc. All rights reserved.</span>
      </div>
    </div>


  </div>
</template>

<script>
  import TypeIt from 'typeit'
  import { Modal } from 'ant-design-vue';
  import { h } from 'vue';
  export default {
    name: "indexComponent",
    data() {
      return {
        isMobile: false,
        isAndroid: false,
        androidURL: "https://storage.yyspay.top/36/catsvpn.apk",
        macosURL: "https://storage.yyspay.top/36/catsvpn.dmg",
        windowURL: "https://storage.yyspay.top/36/catsvpn.exe"
      };
    },
    created() {
      this.detectDevice();
    },
    methods: {

      detectDevice() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // 判断 Android 系统
        this.isAndroid = /android/i.test(userAgent);

      },

      downloadAndroid() {

      },

      isModalMac() {
        Modal.info({
          closable: true,
          title: '安装提示',
          content: h('div', {}, [
            h('p', {
              style: {
                fontSize: '14px', // 调整字体大小
                fontWeight: '500', // 调整字体粗细
                color: '#333', // 调整字体颜色
                lineHeight: '1.5' // 调整行高，增加可读性
              }
            }, '为了确保软件正常运行，您的设备需要 macOS 系统版本 15.0（Sonoma） 或更高版本。'),


            h('p', ''), // 空行
            h('p', ''), // 空行

            h('p', {
              style: {
                fontSize: '14px',
                fontWeight: '500',
                color: '#333',
                lineHeight: '1.5'
              }
            }, '如果您遇到无法打开的问题，请按照以下步骤操作：'),

            h('p', {
              style: {
                fontSize: '14px',
                fontWeight: '400',
                color: '#333',
                lineHeight: '1.5'
              }
            }, '•  点击屏幕左上角的苹果图标，选择“关于本机”，查看您的 macOS 版本。'),



            h('p', {
              style: {
                fontSize: '14px',
                fontWeight: '400',
                color: '#333',
                lineHeight: '1.5'
              }
            }, '•  如果系统版本低于 15.0，请更新到最新版本的 macOS。'),
            h('p', {
              style: {
                fontSize: '14px',
                fontWeight: '400',
                color: '#333',
                lineHeight: '1.5'
              }
            }, '•  您可以通过“系统设置” > “软件更新”下载并安装最新系统。'),
            h('p', {
              style: {
                fontSize: '14px',
                fontWeight: '400',
                color: '#333',
                lineHeight: '1.5'
              }
            }, '•  更新完成后，请重新启动设备并再次尝试打开软件。'),


            h('div', {
              style: {
                textAlign: 'center'
              }
            }, [
              h('p', {
                style: {
                  marginTop: '50px',
                  color: "rgba(153,153,153,0.8)",
                  fontSize: '13px', // 适当调整底部文字大小
                  fontWeight: "400",
                  lineHeight: '1.5'
                }
              }, '如有任何问题，欢迎联系客服团队。感谢您的理解与支持！')
            ])
          ]),
          okText: '确认下载',
          width: 550,
          cancelText: '取消',
          onOk() {
            // 文件直链下载
            const downloadUrl = "https://storage.yyspay.top/36/catsvpn.dmg"; // 替换为实际的文件直链
            console.log(downloadUrl);
            // 创建隐藏的 <a> 标签
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = ""; // 如果需要保存文件时设置默认文件名，可填入名称
            link.style.display = "none"; // 隐藏链接
            document.body.appendChild(link);

            // 触发点击事件
            link.click();

            // 删除 <a> 标签
            document.body.removeChild(link);
          },
        });

      },

      isModalWin() {
        Modal.info({
          closable: true,
          title: '安装提示',
          content: h('div', {}, [
            h('p', {
              style: {
                fontSize: '14px', // 调整字体大小
                fontWeight: '500', // 调整字体粗细
                color: '#333', // 调整字体颜色
                lineHeight: '1.5' // 调整行高，增加可读性
              }
            }, '由于360安全软件的误报，可能会将安装包标记为风险，建议在安装前退出所有杀毒软件。'),

            h('p', ''), // 空行
            h('p', {
              style: {
                fontSize: '14px',
                fontWeight: '500',
                color: '#333',
                lineHeight: '1.5'
              }
            }, '请放心，我们的软件经过严格测试，绝对安全无毒。'),

            h('p', {
              style: {
                fontSize: '14px',
                fontWeight: '600',
                color: '#000', // 强调承诺部分颜色
                lineHeight: '1.5'
              }
            }, '我们的承诺：'),

            h('p', {
              style: {
                fontSize: '14px',
                fontWeight: '400',
                color: '#333',
                lineHeight: '1.5'
              }
            }, '•  隐私保护：我们重视您的隐私，不会收集个人信息。'),

            h('p', {
              style: {
                fontSize: '14px',
                fontWeight: '400',
                color: '#333',
                lineHeight: '1.5'
              }
            }, '•  安全性：软件不含恶意代码或病毒，您可以放心使用。'),

            h('div', {
              style: {
                textAlign: 'center'
              }
            }, [
              h('p', {
                style: {
                  marginTop: '50px',
                  color: "rgba(153,153,153,0.8)",
                  fontSize: '13px', // 适当调整底部文字大小
                  fontWeight: "400",
                  lineHeight: '1.5'
                }
              }, '如有任何问题，欢迎联系客服团队。感谢您的理解与支持！')
            ])
          ]),
          okText: '确认下载',
          width: 550,
          cancelText: '取消',
          onOk() {
            // 文件直链下载
            const downloadUrl = "https://storage.yyspay.top/36/catsvpn.exe"; // 替换为实际的文件直链
            console.log(downloadUrl);
            // 创建隐藏的 <a> 标签
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = ""; // 如果需要保存文件时设置默认文件名，可填入名称
            link.style.display = "none"; // 隐藏链接
            document.body.appendChild(link);

            // 触发点击事件
            link.click();

            // 删除 <a> 标签
            document.body.removeChild(link);
          },
        });

      },

      showModalTf() {
        const isMobile = window.innerWidth <= 390; // 或使用其他方式判断移动设备

        Modal.info({
          closable: true,
          title: '安装提示',
          style: !isMobile ? "" : "top: 10px",
          content: h('div', {}, [
            h('p', {
              style: {
                color: "rgba(153,153,153,1)",
                fontSize: '15px',
                fontWeight: "500"
              }
            }, '注意：中国国区的App Store 无法上架 VPN 应用。请按照以下步骤进行下载：'),
            h('p', { style: { textAlign: 'center', color: '#000', fontWeight: '800', fontSize: '16px' } }, '第一步'),
            h('p', { style: { textAlign: 'center', color: '#000', fontWeight: '800', fontSize: '16px' } }, '先安装TestFlight'),
            h('div', {
              style: { textAlign: 'center' }
            }, [
              h('p', {
                style: {
                  textAlign: 'center', color: 'rgb(57, 57, 57)', fontWeight: '800', fontSize: '13px', display: 'inline-block', // 确保 transform 生效
                  animation: 'textScale 1.5s infinite',
                  '-webkit-animation': 'textScale 1.5s infinite'
                }
              }, '安装TestFlight后，返回该页面执行第二步！'),
            ]),
            h('div', {
              style: {
                display: "flex",
                background: "rgba(0,0,0,0.05)",
                borderRadius: "10px",
                width: " 100 %",
                alignItems: "center",
                height: "80px",
                padding: "0 15px",
                marginTop: "15px",
              }
            }, [
              h('div', { class: "tf-img" }),
              h('div', {
                style: {
                  marginLeft: "10px",
                  backgroundRepeat: "no-repeat"
                }
              }, [
                h('div', {
                  style: {
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: "800",
                  }
                }, "TestFlight"),
                h('div', {
                  style: {
                    fontSize: "12px",
                    color: "#7a7a7a"
                  }
                }, isMobile ? "苹果官方测试平台" : "Apple官方测试平台")
              ]),
              h('div', {
                style: {
                  marginLeft: "auto"
                }
              }, [
                h('button', {
                  class: "am_button",
                  style: {
                    background: "#000",
                    borderRadius: "18px",
                    color: "#fff",
                    fontSize: "15px",
                    outline: "none",
                    border: "0",
                    height: "36px",
                    padding: " 0 20px",
                    lineHeight: "1",
                    fontWeight: "800",
                  },
                  onClick: () => {
                    window.open('https://testflight.apple.com/join/HfxuJD7z', '_blank');  // 在新标签页中打开链接
                  }

                }, "安装"),

              ])
            ]
            ),
            h('p', { style: { textAlign: 'center', color: '#000', fontWeight: '800', marginTop: "30px", fontSize: '16px' } }, '第二步'),
            h('p', { style: { textAlign: 'center', color: '#000', fontWeight: '800', fontSize: '16px' } }, '点击下方安装客戶端'),
            h('div', {
              style: {
                display: "flex",
                background: "rgba(0,0,0,0.05)",
                borderRadius: "10px",
                width: " 100 %",
                alignItems: "center",
                height: "80px",
                padding: "0 15px",
                marginTop: "15px",
              }
            }, [
              h('div', {
                class: "ios-png",
                style: {
                  width: '50px', height: '50px'
                }
              }),
              h('div', {
                style: {
                  marginLeft: "10px",
                  backgroundRepeat: "no-repeat"
                }
              }, [
                h('div', {
                  style: {
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: "800",
                  }
                }, "翻墙猫"),
                h('div', {
                  style: {
                    fontSize: "12px",
                    color: "#7a7a7a"
                  }
                }, "1.2.0")
              ]),
              h('div', {
                style: {
                  marginLeft: "auto"
                }
              }, [
                h('button', {
                  class: "am_button",
                  style: {
                    background: "#000",
                    borderRadius: "18px",
                    color: "#fff",
                    fontSize: "15px",
                    outline: "none",
                    border: "0",
                    height: "36px",
                    padding: " 0 20px",
                    lineHeight: "1",
                    fontWeight: "800",
                  },
                  onClick: () => {
                    window.open('https://testflight.apple.com/join/HfxuJD7z', '_blank');  // 在新标签页中打开链接
                  }
                }, "安装"),
              ])
            ]
            ),
            h('div', {
              style: {
                textAlign: 'center'
              }
            }, [
              [
                h('p', {
                  style: {
                    marginTop: '50px',
                    // color: "rgba(153,153,153,0.8)",
                    fontSize: '15px',
                    fontWeight: "800"
                  }
                }, '安装完成后，请勿删除 TestFlight 和翻墙猫。')
              ],
              h('p', {
                style: {
                  marginTop: '50px',
                  color: "rgba(153,153,153,0.8)",
                  fontSize: '13px',
                  fontWeight: "400"
                }
              }, '如有任何问题，欢迎联系客服团队。感谢您的理解与支持！')
            ])
          ]),
          width: 550,
          footer: null, // 去掉底部按钮
        });
      }
    },

    mounted() {
      this.isMobile = window.innerWidth <= 768; // 或使用其他方式判断移动设备

      const typeIt = new TypeIt(this.$refs.typewriter, {
        speed: 200,
        loop: true,
        breakLines: false,
        deleteSpeed: 100,
      });

      typeIt.type(!this.isMobile ? "安全稳定 值得信赖" : "安全稳定 值得信赖")
        .pause(6000)
        .delete()

        .type("快如闪电 连接世界")
        .pause(5000)
        .delete()
        ;

      if (!this.isMobile) {
        typeIt.type("🏄体验快速安全的网络服务。")
          .pause(5000)
          .delete();
      }

      typeIt.go();
    }
  }
</script>

<style>
  .ant-modal-header .ant-modal-close {
    display: none;
    /* 隐藏关闭按钮 */
  }

  .ios-png {
    background-image: url(@/assets/ios_logo.jpg);
    background-size: 80%;
    /* 设置背景图像的大小为容器的50% */
    background-repeat: no-repeat;
    /* 不重复背景图像 */
    background-position: center;
    /* 图片居中显示 */
    border-radius: 15px;
    background-color: white;
    border: 1px solid rgb(232, 232, 232);
    /* 添加边框，颜色为 RGB(240, 240, 240) */
  }

  .tf-img {
    width: 50px;
    height: 50px;
    background-image: url(@/assets/tf.png);
    background-size: 100%;
    /* 设置背景图像的大小为容器的50% */
    background-repeat: no-repeat;
    /* 不重复背景图像 */
    background-position: center;

  }
</style>
<style scoped>
  .typeit-cursor {
    border-left: 2px solid;
    /* 调整光标的宽度 */
    animation: blink 0.7s step-end infinite;
    /* 添加闪烁效果 */
    background: linear-gradient(90deg, #4e9ee8, #00aaff);
    /* 渐变色 */
    /* 设置高度以匹配文本的高度 */
    height: 1em;
    /* 根据你的字体大小调整 */
  }

  @keyframes blink {
    50% {
      opacity: 0;
      /* 使光标闪烁 */
    }
  }

  .home {
    width: 100%;
    height: 100%;
  }

  .home-bgimg {
    position: fixed;
    top: 0;
  }

  .home-video {
    width: auto;
    height: auto;
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -999;
  }

  .home-content {
    color: rgb(255, 255, 255);
    margin-left: 12vw;
    /* margin-top: 5vh; */
    margin-right: 12vw;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .home-content a:hover {
    opacity: 0.8;
  }

  .img-bg {
    background: rgba(238, 238, 238, .5);
    margin-right: 12px;
    border-radius: 8px;
    cursor: pointer;
  }

  .content-title {
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: 40px;
    line-height: 78px;
    margin-top: 16vh;
    font-weight: 600 !important;
  }

  .content-introduce-1,
  .content-introduce-2 {
    margin: 0px 0px 50px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    font-size: 70px;
    line-height: 78px;
  }

  .content-copyright {
    color: #d7d7d7;
    font-size: 12px;
    margin-bottom: 10px;
    font-weight: 500 !important;
  }

  .content-bottom {
    height: 19%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }

  .concat-wrap {
    display: flex;
    flex-direction: row;
  }

  .concat-wrap div,
  .concat-wrap a {
    display: flex;
    justify-content: flex-start;
    text-decoration: none;
    color: #fff;
    padding-right: 12px;
    align-items: center;
  }

  .icon-label {
    margin-left: 6px;
    font-size: 14px;
    font-weight: 400;
    /* font-family: Roboto, sans-serif; */
    line-height: 2vh;
  }

  .content-introduce-1 {
    margin-right: 60px;
  }

  .content-introduce-text {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    min-height: 20%;
  }



  .img-bg-appstore-mobile,
  .img-bg-android-mobile,
  .img-bg-mac-mobile,
  .img-bg-windows-mobile,
  .img-bg-testflight-mobile {
    display: none;
  }







  @media(max-width:900px) {
    .home-video {
      bottom: 9999px;
    }

    .top-title {
      display: none !important;
    }

    .content-title {
      font-size: 1.0628rem !important;
      font-family: 900 !important;
    }

    .home {
      /* background: url(@/assets/home-bg-img.png) no-repeat; */
      background: url('@/assets/mobile_bg.webp') no-repeat;

      background-size: 100% 100%;
      padding: 24px 15px 0px 15px;
      width: 100vw;
      height: 100vh;
      box-sizing: border-box;
      /* 确保内边距不会增加元素的总宽高 */
      /* overflow: hidden; */
      /* 防止出现滚动条 */
    }


    .home-content {
      margin: 0;
    }

    .content-title {
      margin-top: 1.3rem;
      font-size: 24px;
      font-family: 600;
    }

    .content-introduce-text {
      flex-direction: column;
      min-height: 160px !important;
      font-size: 2em !important;
      margin: 0px 0px 50px;
    }



    .content-introduce-1 {
      margin: 0;
    }

    .img-bg-appstore,
    .img-bg-android,
    .img-bg-mac,
    .img-bg-windows,
    .img-bg-testflight {
      display: none;
    }

    .img-bg-appstore-mobile,
    .img-bg-android-mobile,
    .img-bg-mac-mobile,
    .img-bg-windows-mobile,
    .img-bg-testflight-mobile {
      display: block;
    }

    .content-download {
      display: flex;
      flex-wrap: wrap;
    }

    .content-download a {
      margin-bottom: 5px;
    }

    .concat-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .concat-wrap .item {
      margin-top: 10px;
    }

    .content-copyright {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
</style>