import { createRouter, createWebHashHistory } from 'vue-router';  // 使用命名导入

import Home from '../components/indexComponent.vue';
// import payResult from '../components/payResult.vue';
// import register from '../components/register.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/pay',
    name: 'payResult',
    component: () => import('../components/payResult.vue'),  // 正确的动态导入方式
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../components/register.vue'),  // 正确的动态导入方式
  },
  {
    path: '/:pathMatch(.*)*',  // 处理所有未匹配的路由
    name: 'NotFound',
    component: Home,  // 动态导入 404 组件
  },
  // 在这里添加其他路由
];

const router = createRouter({
    history: createWebHashHistory(),  // 使用哈希模式
  routes,
});

export default router;
